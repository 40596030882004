<script lang="ts" setup>
import type { Component, Ref } from "vue";
import type { SecondaryDataInterface } from "@/types/ContentType";

const loadedComponents: Ref<Component[]> = ref([]);
const loadedComponentsData: Ref<SecondaryDataInterface[]> = ref([]);
const pageComponents = useState<SecondaryDataInterface[]>("selectedComponents");

const initComponents = () => {
  pageComponents.value?.forEach((comp) => {
    const AsyncComp = markRaw(
      defineAsyncComponent(
        () =>
          import(
            `../components/smartComponents/${comp.conf.componentName}/${comp.conf.componentName}.vue`
          )
      )
    );
    loadedComponents.value.push(AsyncComp);
    loadedComponentsData.value.push(comp);
  });
};

initComponents();
</script>

<template>
  <component
    v-for="(comp, index) in loadedComponents"
    :key="index"
    :is="comp"
    :data-component="loadedComponentsData[index].conf.componentName"
    :contentData="loadedComponentsData[index]"
  />
</template>
